<template>
 <div>
   <router-view></router-view>
 </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
    }
  },
  components: {},
};
</script>

<style lang="less">
</style>
