import Vue from 'vue'
import App from './App.vue'
import routes from "@/router"
import ElementUI from 'element-ui';
import store from "@/store";
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.prototype.$toJson = function(str){
  var json = eval("(" + str + ")");
  return json;
}
Vue.use(ElementUI);
Vue.prototype.$code = "0l23456789@#$%^&*()_+ABCDEFGHIJKjdkLMNOPQRSTUVsdsWXYZ";
new Vue({
  render: h => h(App),
  router:routes,
  store,
}).$mount('#app')
