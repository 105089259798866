import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import('@/views/Login.vue');
const Regist = () => import("@/views/Register");
const Forget = () => import("@/views/Forget");
const Home = () => import("@/views/Home");
const Test = () => import("@/views/Test");
const Record = () => import("@/views/Record");
const Rest = () => import("@/views/Rest")
const Order = () => import("@/views/Order")
// const EditBookTrue = () => import("@/views/EditBookTrue")
// const Modify = () => import("@/views/Modify")
Vue.use(VueRouter)
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
//重写VueRouter.prototype身上的push方法了
VueRouter.prototype.push = function(location, resolve, reject) {
  //第一个形参：路由跳转的配置对象（query|params）
  //第二个参数：undefined|箭头函数（成功的回调）
  //第三个参数:undefined|箭头函数（失败的回调）
  if (resolve && reject) {
    //push方法传递第二个参数|第三个参数（箭头函数）
    //originPush：利用call修改上下文，变为(路由组件.$router)这个对象，第二参数：配置对象、第三、第四个参数：成功和失败回调函数
    originPush.call(this, location, resolve, reject);
  } else {
    //push方法没有产地第二个参数|第三个参数
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
//重写VueRouter.prototype身上的replace方法了
VueRouter.prototype.replace = function(location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path:"/forget",
    name:"Forget",
    component: Forget
  },
  {
    path: "/regist",
    name: "Regist",
    component: Regist
  },
  {
    path: "/home",
    name: 'Home',
    component: Home
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/record',
    name: 'Record',
    component: Record
  },
  {
    path: '/rest',
    name: 'Rest',
    component: Rest
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next) => {
  // console.log(to,from,next)
  let satoken = window.localStorage.getItem('satoken')
  if(to.path === '/regist' || to.path === '/' || to.path === '/forget'){
    next();
  }else {
    if(satoken !== '' && satoken !== 'undefined' && satoken !== 'null'){
      next();
    }
  }
})
export default router
