import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex)
const actions = {
	settoken(context,value){
    context.commit('SETTOKEN',value)
  },
  setusername(context,value){
    context.commit('SETUSERNAME',value)
  }
}
const mutations = {
	SETTOKEN(context,value){
    context.satoken = value
  },
  SETUSERNAME(context,value){

  }
}
const state = {
	satoken:"",
}
const store = new Vuex.Store({
	actions,
	mutations,
	state,
})

export default store;